import Home from "./components/Home";
import GasEstacionario from "./components/GasEstacionario";
import Vehicular from "./components/Vehicular";
import Cilindros from "./components/Cilindros";
import Sucursales from "./components/Sucursales";
import AppPedidos from "./components/AppPedidos";
import ThankYou from "./components/ThankYou";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="" element={<Home />} />
        <Route exact path="/gas-estacionario" element={<GasEstacionario />} />
        <Route exact path="/vehicular" element={<Vehicular />} />
        <Route exact path="/cilindros" element={<Cilindros />} />
        <Route exact path="/sucursales" element={<Sucursales />} />
        <Route exact path="/app-pedidos" element={<AppPedidos />} />
        <Route exact path="/gracias" element={<ThankYou />} />
      </Routes>
    </>
  );
}

export default App;
