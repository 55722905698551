import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/app-pedidos.css";
import beneficios from "../assets/img/gassultana-imagen-beneficios.jpg";
import apps from "../assets/img/icono-apps.png";
import img1 from "../assets/img/icono-facilidad-rapidez.png";
import img2 from "../assets/img/icono-historial.png";
import img3 from "../assets/img/icono-direcciones.png";
import img4 from "../assets/img/icono-factura.png";
import img5 from "../assets/img/icono-llamadas.png";
import img6 from "../assets/img/icono-efectivo.png";
import img7 from "../assets/img/icono-tarjeta.png";
import img8 from "../assets/img/gassultana-app-estacionario.jpg";
import img9 from "../assets/img/gassultana-app-cilindros.jpg";
import img10 from "../assets/img/gassultana-app-vehicular.jpg";
import footer from "../assets/img/gas-sultana-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

const AppPedidos = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.servicio.length <= 1) { error = true; msj = "El campo servicio es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="app-pedidos">
            {/* META TAGS */}
            <Helmet>
                <title>  App | Gas Sultana </title>
                <meta name="description" content="" />
                <link rel="canonical" href={currentUrl} />
            </Helmet>

            <Header />
            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1 className="fw-bold fs-50 text-white desktop-item"> Haz tu pedido de gas LP <br /> por nuestra App </h1>
                            <h1 className="fw-bold fs-50 text-black mobile-item"> Haz tu pedido de gas LP <br /> por nuestra App </h1>
                            <a href="#contacto" className="btn-cta mt-2 mb-4 fw-bold"> COMENZAR AHORA </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="fw-bold text-grey-2">Conoce la App Pedidos Gas Sultana</h3>
                            <p className="mt-4 ff-lato">
                                ¡Ahora es mucho más sencillo solicitar tu gas LP a domicilio! Encuentra nuestra App en App Store o Google Play e
                                ingresa <br /> para realizar tus pedidos de gas LP desde tu celular.
                            </p>
                            <p className="ff-lato ">
                                Con esta herramienta puedes adquirir el gas necesario a través de los pedidos personalizados sujetos a un monto mínimo <br />
                                para tanques estacionarios y cilindros, además, nuestra App te permite elegir el método de pago de tu preferencia.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC3 */}
            <div className="sec3 d-pad">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center text-white">
                            <h3 className="fw-bold">¡Descubre una mejor manera de solicitar tu gas LP con nuestra App!</h3>
                            <p className="mt-3 fs-18">Descárgala por:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-12">
                            <div class="text-center">
                                <img src={apps} alt="" className="app-iconos" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC4*/}
            <div className="sec4 text-center d-pad">
                <div className="box bg-dark-grey-02">
                    <div class="row">
                        <div class="col-md-12 mb-4">
                            <h3 className="fw-bold">¡Es muy sencillo usar la App Pedidos Gas Sultana!</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <p> <span className="text-red fw-bold"> 1. </span> Crea una cuenta y <br /> actívala para iniciar sesión.</p>
                        </div>
                        <div class="col-md-4">
                            <p> <span className="text-red fw-bold"> 2. </span>Revisa el botón de <br /> "Promociones" para <br /> conocer nuestras ofertas.</p>
                        </div>
                        <div class="col-md-4">
                            <p> <span className="text-red fw-bold"> 3. </span>Presiona el apartado <br /> "Haz un pedido nuevo" para <br /> comenzar tu solicitud.</p>
                        </div>
                    </div>
                    <div class="row mt-md-3">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <p> <span className="text-red fw-bold"> 1. </span>Ingresa el monto de tu <br /> pedido y la dirección de tu <br /> domicilio.</p>
                        </div>
                        <div class="col-md-4">
                            <p> <span className="text-red fw-bold"> 2. </span>Confirma tu solicitud, <br /> ¡y listo! </p>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </div>

            {/* SEC5 */}
            <div className="sec5">
                <div className="container">
                    <h3 className="fw-bold mb-5 text-center">Beneficios de usar nuestra App </h3>
                    <div class="row">
                        <div class="col-md-4 text-center">
                            <img src={img1} alt="" className="iconos mb-3" />
                            <p className="fw-bold">Brinda facilidad y rapidez <br /> para hacer tu pedido de <br /> gas LP</p>
                        </div>
                        <div class="col-md-4 text-center">
                            <img src={img2} alt="" className="iconos mb-3" />
                            <p className="fw-bold">Guarda el historial de tus <br /> servicios para llevar un <br /> control de tus gastos</p>
                        </div>
                        <div class="col-md-4 text-center">
                            <img src={img3} alt="" className="iconos mb-3" />
                            <p className="fw-bold">Guarda tus direcciones de <br /> surtido</p>
                        </div>
                    </div>
                    <div class="row mt-md-3">
                        <div class="col-md-2"></div>
                        <div class="col-md-4 text-center">
                            <img src={img4} alt="" className="iconos mb-3" />
                            <p className="fw-bold">Es muy fácil solicitar tu <br /> factura</p>
                        </div>
                        <div class="col-md-4 text-center">
                            <img src={img5} alt="" className="iconos mb-3" />
                            <p className="fw-bold">Atención inmediata sin <br /> caer en llamadas en espera </p>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </div>

            {/* SEC6 */}
            <div className="sec6 bg-dark-grey-02">
                <div className="box2">
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <h3 className="fw-bold text-white text-center">Realiza tu pago al momento de tu surtido con <br /> el método de tu preferencia</h3>
                        </div>
                    </div>
                    <div class="row mt-md-3">
                        <div class="col-md-2"></div>
                        <div class="col-md-4 d-flex align-items-center">
                            <img src={img6} alt="" className="w-25" />
                            <p className="ms-4 fw-bold text-white text-center">Efectivo</p>
                        </div>
                        <div class="col-md-4 d-flex align-items-center">
                            <img src={img7} alt="" className="w-25" />
                            <p className="ms-4 fw-bold text-white text-center">Tarjeta</p>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </div>

            {/* SEC7 */}
            <div className="sec7 d-pad">
                <div class="row">
                    <div class="col-md-12 mb-4">
                        <h2 className="fw-bold text-center">Conoce todos nuestros servicios</h2>
                    </div>
                </div>
                <div class="row intermediate position-relative">
                    <div class="col-md-4">
                        <img src={img8} alt=" Recarga de cilindros de gas - sucursal de gas sultana " className="w-100" />
                        <div className="position-relative text-center mt-md-3 mt-5">
                            <div className="btn-box bg-white position-absolute translate-middle start-50">
                                <h8 className="fw-bold">Estacionario</h8>
                                <p>
                                    Llevamos el gas lp hasta tu hogar o negocio mediante nuestras unidades especializadas.
                                </p>
                                <Link to={'/gas-estacionario'} className="btn-cta">MÁS INFORMACIÓN</Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 espacio">
                        <img src={img9} alt=" Recarga de cilindros de gas - servicio de conversión " className="w-100" />
                        <div className="position-relative text-center mt-md-3 mt-5">
                            <div className="btn-box bg-white position-absolute translate-middle start-50">
                                <h8 className="fw-bold">Cilindros</h8>
                                <p>
                                    Recarga tus cilindros con gas lp en cualquiera de nuestras sucursales.
                                </p>
                                <Link to={'/cilindros'} className="btn-cta">MÁS INFORMACIÓN</Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 espacio">
                        <img src={img10} alt=" Recarga de cilindros de gas - servicio de conversión " className="w-100" />
                        <div className="position-relative text-center mt-md-3 mt-5">
                            <div className="btn-box bg-white position-absolute translate-middle start-50">
                                <h8 className="fw-bold">Vehicular</h8>
                                <p>
                                    Contamos con un taller exclusivo de conversión de unidades de gasolina a gas LP.
                                </p>
                                <Link to={'/vehicular'} className="btn-cta">MÁS INFORMACIÓN</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC9*/}
            <div className="sec9 bg-dark-grey-02">
                <div class="container">
                </div>
            </div>

            {/* SEC8 */}
            <div className="sec8 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mb-4">Envíanos tus datos para <br /> cotizar el servicio de tu <br /> interés</h2>
                            <img src={footer} className="w-100" />
                        </div>
                        <div className="col-md-5 mt-4">
                            <div className="contacto" id="form">
                                <form onSubmit={onSubmitHandler}>
                                    <input type="text" name="nombre" placeholder="Nombre:" onChange={handleChange} value={lead.nombre || ""} required />
                                    <input type="text" name="email" placeholder="Email:" onChange={handleChange} value={lead.email || ""} required />
                                    <input type="text" name="servicio" placeholder="Servicio:" onChange={handleChange} value={lead.servicio || ""} required />
                                    <input type="text" name="telefono" placeholder="Teléfono:" onChange={handleChange} value={lead.telefono || ""} required />
                                    <textarea name="mensaje" placeholder="Mensaje:" onChange={handleChange} value={lead.mensaje || ""} required />
                                    <button type="submit" className="btn-cta mt-3"> ¡CONTÁCTANOS! </button>
                                </form>
                            </div>
                        </div>
                        <div className="coml-md-1"></div>
                    </div>
                </div>
            </div>
            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default AppPedidos;