import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/home.css";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
import icono1 from "../assets/img/gassultana-icono-fuego-azul.png";
import icono2 from "../assets/img/gassultana-icono-emisiones.png";
import icono3 from "../assets/img/gassultana-icono-cuestiones-de-seguridad.png";
import icono4 from "../assets/img/gassultana-icono-fuente-energia.png";
import nosotros from "../assets/img/nosotros.png";
import estacionario from "../assets/img/gas-sultana-servicios-estacionario.jpg";
import cilindros from "../assets/img/gas-sultana-servicios-cilindros.jpg";
import vehicular from "../assets/img/gas-sultana-servicios-vehicular.jpg";
import footer from "../assets/img/gas-sultana-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

const Home = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        new Glide('.glide', { type: 'slider', perView: 1 }).mount();
    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.servicio.length <= 1) { error = true; msj = "El campo servicio es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="home">
            {/* META TAGS */}
            <Helmet>
                <title>Gas LP | Gas Sultana </title>

                <link rel="canonical" href="https://www.gassultana.com/" />
                <meta name="description"
                    content="En Gas Sultana somos una empresa dedicada a la distribución de gas LP con presencia de más de 60 años en la región noreste de la república. Contáctanos." />

                    <meta property="og:title" content="Gas LP | Gas Sultana" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="{{ asset('assets/img/favicon.ico') }}" />
                    <meta property="og:url" content="https://www.gassultana.com/" />
                    <meta property="og:description"
                        content="En Gas Sultana somos una empresa dedicada a la distribución de gas LP con presencia de más de 60 años en la región noreste de la república. Contáctanos." />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@GasSultana" />
                    <meta name="twitter:creator" content="@Futurité" />
                    <meta name="twitter:url" content="https://www.gassultana.com/" />
                    <meta name="twitter:title" content="Gas LP | Gas Sultana" />
                    <meta name="twitter:description"
                        content="En Gas Sultana somos una empresa dedicada a la distribución de gas LP con presencia de más de 60 años en la región noreste de la república. Contáctanos." />
                    <meta name="twitter:image" content="{{ asset('assets/img/favicon.ico') }}" />

                    <script type="application/ld+json">
                        {`
                            {
                                "@context": "https://schema.org",
                                "@type": "LocalBusiness",
                                "name": "Gas Sultana Gas LP",
                                "image": "https://www.gassultana.com/",
                                "@id": "https://www.gassultana.com/",
                                "url": "https://www.gassultana.com/",
                                "telephone": "+52 (81) 8360 1100",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "Carretera a Reynosa KM 3.5",
                                    "addressLocality": "Guadalupe",
                                    "postalCode": "67190",
                                    "addressCountry": "MX"
                                }
                            }
                        `}
                    </script>
            </Helmet>

            <Header />

            <div className="container-fluid sec1 p-0">
                <div className="glide position-relative">
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides text-white">
                            <div className="glide__slide slide1"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide2"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide3"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide4"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide5"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide6"><div className="w-100 h-100 op-grey"></div></div>
                        </div>
                    </div>
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        <button className="glide__bullet" data-glide-dir="=0"></button>
                        <button className="glide__bullet" data-glide-dir="=1"></button>
                        <button className="glide__bullet" data-glide-dir="=2"></button>
                        <button className="glide__bullet" data-glide-dir="=3"></button>
                        <button className="glide__bullet" data-glide-dir="=4"></button>
                        <button className="glide__bullet" data-glide-dir="=5"></button>
                    </div>
                    <div className="glide__arrows" data-glide-el="controls">
                        <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                            <span className="icon-hero-arrow-left text-white"></span>
                        </button>
                        <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                            <span className="icon-hero-arrow-right text-white"></span>
                        </button>
                    </div>
                </div>
                <div className="text-center text-white position-absolute top-50 start-50 translate-middle w-75">
                    <p className="mb-2">Servicio de distribución de Gas Lp</p>
                    <h1 className="fw-bold fs-50">Tu mejor opción en Gas LP</h1>
                    <p className="mb-4">Distribución eficiente y segura en Monterrey y su área metropolitana.</p>
                </div>
            </div>

            <div className="sec2 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-4">Conoce más sobre el gas lp</h2>
                            <p className="mb-5 ff-lato">
                                El gas licuado de petróleo, comúnmente conocido como gas lp,
                                es un hidrocarburo compuesto<br /> principalmente por propano y butano.
                                Estos componentes se extraen durante el proceso de<br /> fraccionamiento
                                del petróleo y el gas natural.
                            </p>
                            <p>
                                Algunas de las características más distintivas del gas lp son:
                            </p>
                            <p>
                                <span className="text-red fw-bold">•</span> Combustión casi completa<br />
                                <span className="text-red fw-bold">•</span> Incoloro y transparente<br />
                                <span className="text-red fw-bold">•</span> Baja toxicidad<br />
                                <span className="text-red fw-bold">•</span> Alto rendimiento<br />
                            </p>
                            <a href="https://api.whatsapp.com/send/?phone=528183601100&text&type=phone_number&app_absent=0" className="btn-cta">REALIZA TU PEDIDO</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec3 d-pad bg-grey" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-4 fs-25">VENTAJAS DE USAR GAS LP PARA USO INDUSTRIAL, DOMÉSTICO Y VEHICULAR</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-2">
                            <div className="d-flex align-items-center">
                                <img src={icono1} className="icon-img me-3" />
                                <p className="mb-0">
                                    Ahorro de tiempo y consumo gracias a su alto poder calorífico.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <img src={icono2} className="icon-img me-3" />
                                <p className="mb-0">
                                    Las emisiones de contaminantes regulados son muy bajas.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <img src={icono3} className="icon-img me-3" />
                                <p className="mb-0">
                                    Se puede almacenar hasta dos meses de consumo.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <img src={icono4} className="icon-img me-3" />
                                <p className="mb-0">
                                    Es una fuente de energía económica y versátil.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec2 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-5 text-grey fs-50">NUESTROS SERVICIOS EN GAS LP</h2>
                            <h2 className="fw-bold mb-3 ff-lato">ESTACIONARIO</h2>
                            <p className="mb-5">
                                Llevamos el gas lp hasta tu hogar o negocio mediante
                                nuestras unidades especializadas. <br /> directo
                                a tu tanque estacionario a domicilio.
                            </p>
                            <div className="image-box position-relative text-center">
                                <img src={estacionario} className="w-100" alt="Gas Sultana - Estacionario" />
                                <div className="btn-box bg-white position-absolute translate-middle start-50">
                                    <Link to={'/gas-estacionario'} className="btn-cta">MÁS INFORMACIÓN</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec2 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-3 ff-lato">CILINDROS</h2>
                            <p className="mb-5">
                                Recarga tus cilindros con gas lp en cualquiera de nuestras sucursales, donde personal capacitado<br />se encargará de ayudarte.
                            </p>
                            <div className="image-box position-relative text-center">
                                <img src={cilindros} className="w-100" alt="Gas Sultana - Cilindros" />
                                <div className="btn-box bg-white position-absolute translate-middle start-50">
                                    <Link to={'/cilindros'} className="btn-cta">MÁS INFORMACIÓN</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec2 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-3 ff-lato">VEHICULAR</h2>
                            <p className="mb-5">
                                Contamos con un taller exclusivo de conversión de unidades de gasolina a gas lp. Impulsa tu<br />economía y protege el medio ambiente.
                            </p>
                            <div className="image-box position-relative text-center">
                                <img src={vehicular} className="w-100" alt="Gas Sultana -  Vehicular" />
                                <div className="btn-box bg-white position-absolute translate-middle start-50">
                                    <Link to={'/vehicular'} className="btn-cta">MÁS INFORMACIÓN</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec6 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ticket-box bg-grey">
                                <span className="icon icon-tickets-color icon-circle"></span>
                                <h2 className="fw-bold mb-3 ff-lato text-break">TICKETS PERSONALIZADOS</h2>
                                <p className="mb-5">
                                    Identificación de nuestros clientes a través de RFID.
                                    Siempre obtendrá<br /> un ticket con fecha, hora, litros
                                    descargados, importe total y la unidad<br /> que lo realizó.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec4">
                <div className="container">
                    <div className="row">
                        <div className="schedule-box bg-grey">
                            <h2 className="fw-bold fs-58 mb-5 text-center">HORARIOS</h2>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="ls-25 mb-1 fw-bold">LUNES A SÁBADOS</p>
                                    <p>7:00 AM • 8:00 PM</p>
                                    <p className="ls-25 mb-1 fw-bold">FESTIVOS</p>
                                    <p>7:00 AM • 8:00 PM</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="ls-25 mb-1 fw-bold">FORMAS DE PAGO</p>
                                    <div className="d-flex  align-items-center">
                                        <span className="d-inline-block icon icon-cash fs-40 text-light-yellow me-3"></span>
                                        <p className="d-inline-block mb-0">Efectivo</p>
                                    </div>
                                    <div className="d-flex  align-items-center">
                                        <span className="d-inline-block icon icon-credit-card fs-40 text-light-yellow me-3"></span>
                                        <p className="d-inline-block mb-0">
                                            Tarjeta de Crédito y Débito<br />
                                            <small className="fs-12 text-grey">Visa y MasterCard</small>
                                        </p>
                                    </div>
                                    <div className="d-flex  align-items-center">
                                        <span className="d-inline-block icon icon-voucher fs-40 text-light-yellow me-3"></span>
                                        <p className="d-inline-block mb-0">
                                            Prepago<br />
                                            <small className="fs-12 text-grey">Vales</small>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec6 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold fs-58 mb-4 ls-25">SUCURSALES</h2>
                            <p className="ls-25">ENCUENTRA TU SUCURSAL MÁS CERCANA.</p>

                            <iframe className="w-100" title="mapa"
                                src="https://www.google.com/maps/d/embed?mid=1t2qfKCS2r4ht-JqP1DVGflGtcB40Ymg&ehbc=2E312F"
                                height="600"></iframe>

                        </div>
                    </div>
                </div>
            </div>

            <div className="sec7 d-pad bg-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold fs-58 mb-5 ls-25 text-center">NOSOTROS</h2>
                            <p className="ls-25 text-center">
                                SOMOS UNA EMPRESA DEDICADA A LA DISTRIBUCIÓN DE GAS LP CON<br />PRESENCIA DE MÁS DE 60 AÑOS EN LA REGIÓN NORESTE DE LA REPÚBLICA.
                            </p>
                            <div className="us-box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text-custom-alignment mb-5">
                                            Comercializamos y suministramos Gas LP para soluciones industriales y residenciales.
                                            <br />
                                            <br />
                                            Contamos con más de 40 autotanques disponibles a su servicio los 365 días del año; además de 30 estaciones de carburación y plantas de almacenamiento para realizar recargas de cilindros y automóviles.
                                        </p>
                                        <a href="http://autogassultana.sgcweb.com.mx/index.php?module=isies_facturacion&entryPoint=facturacionEnLinea&content=facturarConsultar" className="btn-cta">Facturación</a>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={nosotros} className="w-100 img-nosotros" alt="Gas LP - Nosotros" />
                                    </div>
                                </div>
                            </div>
                            <p className="ls-25 mt-5 fw-bold fs-18 text-md-start text-center">NUESTROS VALORES</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-integrity-color icon-circle"></span>
                                <p className="ls-25 text-red box-title">INTEGRIDAD</p>
                                <p className="hover-text">
                                    Cumplimos nuestra<br /> palabra.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-service-color icon-circle"></span>
                                <p className="ls-25 text-red box-title">SERVICIO</p>
                                <p className="hover-text">
                                    Nuestro diferenciador.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-excellence-color icon-circle"></span>
                                <p className="ls-25 text-red box-title">EXCELENCIA</p>
                                <p className="hover-text">
                                    Aunque no perfectos, buscando
                                    siempre la mejora.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-humility-color icon-circle"></span>
                                <p className="ls-25 text-red box-title">HUMILDAD</p>
                                <p className="hover-text">
                                    Aceptando oportunidades con
                                    el reto de mejora.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-respect-color icon-circle"></span>
                                <p className="ls-25 text-red box-title">RESPETO</p>
                                <p className="hover-text">
                                    Con dignidad para nuestros clientes,
                                    empleados, familias y comunidades.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqs">
                <div className="container">
                    <h3 className="fw-bold mb-5 cfs">PREGUNTAS FRECUENTES SOBRE NUESTRO SERVICIO.</h3>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ¿Cómo se entrega el gas LP a mi domicilio o negocio?
                                </button>
                            </h3>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    La entrega de gas LP se realiza por medio de un pedido por llamada, WhatsApp o App para programar el servicio. Las unidades acuden a los domicilios, son monitoreadas hasta surtir el servicio y se entrega ticket personalizado.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Cómo puedo saber si necesito rellenar mi tanque de gas LP?
                                </button>
                            </h3>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Cuando la flama tiene muy poca intensidad o no sale absolutamente nada en los cilindros, en tanques estacionarios monitoreando el indicador de nivel que no llegue a 10% de capacidad
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿Qué formas de pago aceptan para la compra de gas LP?
                                </button>
                            </h3>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Las formas de pago aceptadas para la compra de gas lp son efectivo, transferencia electrónica, así como tarjeta de crédito y débito (Visa y MasterCard).
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿Cuál es el horario de entrega?
                                </button>
                            </h3>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Nuestro horario de entrega está diseñado para resultar conveniente y oportuno, por ello abarcamos sábados y días festivos: <br /><br />
                                    <strong>- Lunes a Sábado</strong><br />
                                    7:00 AM a 8:00 PM<br /><br />
                                    <strong>- Festivos</strong><br />
                                    7:00 AM a 8:00 PM
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    ¿Qué sucede si no estoy en casa cuando llega el camión de entrega?
                                </button>
                            </h3>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Se hace una llamada telefónica al cliente y el servicio se pospone para otro día u horario. Es necesario que estés presente cuando se surte el pedido.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    ¿Cómo puedo contratar el servicio de suministro de gas LP?
                                </button>
                            </h3>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Contratar nuestro servicio de gas lp es muy sencillo, solamente necesitas llamarnos o enviarnos un mensaje de whatsapp al número: (81) 8360 1100 o bien llenar el formulario que se encuentra al final de esta página, uno de nuestros asesores le responderá para realizar la contratación.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    ¿Cuánto tiempo tarda en llegar mi pedido de gas LP?
                                </button>
                            </h3>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    El tiempo estimado es durante el transcurso del día y se programa con base en las rutas de las unidades.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec12 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mb-5">Obtén un suministro <br /> constante de Gas LP para <br /> tu hogar o negocio.</h2>
                            <img src={footer} className="w-100" />
                        </div>
                        <div className="col-md-5">
                            <p className="mb-4">
                                ESCRÍBENOS, ESTAMOS PARA ATENDERTE
                            </p>
                            <div className="contacto" id="form">
                                <form onSubmit={onSubmitHandler}>
                                    <input type="text" name="nombre" placeholder="Nombre:" onChange={handleChange} value={lead.nombre || ""} required />
                                    <input type="email" name="email" placeholder="Email:" onChange={handleChange} value={lead.email || ""} required />
                                    <input type="text" name="servicio" placeholder="Servicio:" onChange={handleChange} value={lead.servicio || ""} required />
                                    <input type="tel" name="telefono" placeholder="Teléfono:" onChange={handleChange} value={lead.telefono || ""} required />
                                    <textarea name="mensaje" placeholder="Mensaje:" onChange={handleChange} value={lead.mensaje || ""} required />
                                    <button type="submit" className="btn-cta mt-3"> Enviar </button>
                                </form>
                            </div>
                        </div>
                        <div className="coml-md-1"></div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Home;