import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/gas-estacionario.css";
import icono1 from "../assets/img/gas-sultana-icono-indicador.png";
import icono2 from "../assets/img/gas-sultana-icono-rendimiento.png";
import icono3 from "../assets/img/gas-sultana-icono-conveniencia.png";
import icono4 from "../assets/img/gas-sultana-icono-ahorro.png";
import icono5 from "../assets/img/gas-sultana-icono-impacto.png";
import icono6 from "../assets/img/gas-sultana-icono-seguridad.png";
import img1 from "../assets/img/gas-sultana-estacionario-img-01.jpg";
import footer from "../assets/img/gas-sultana-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Helmet } from 'react-helmet';

const GasEstacionario = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.servicio.length <= 1) { error = true; msj = "El campo servicio es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="gas-estacionario">
            {/* META TAGS */}
            <Helmet>
                <title>Servicio de gas estacionario | Gas Sultana </title>
                <meta name="description" content="En Gas Sultana confía en nosotros para la recarga de cilindros de gas. Servicio confiable y rápido para mantener tu suministro de gas LP al máximo." />
                <link rel="canonical" href={currentUrl} />
                <script type="application/ld+json">
                    {`
                        {
                            "@context":"https://schema.org",
                            "@type":"FAQPage",
                            "mainEntity":[
                                {
                                    "@type":"Question",
                                    "name":"¿Qué es el servicio de gas estacionario?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"Es el servicio de gas a domicilio por medio del cual se suministra gas LP a un tanque estacionario en un hogar o negocio."
                                    }
                                },
                                {
                                    "@type":"Question",
                                    "name":"¿Cómo es el proceso para solicitar el servicio de gas estacionario?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"El pedido puede realizarse por llamada, WhatsApp o app para programar el servicio. Las unidades acuden a los domicilios, son monitoreadas hasta surtir el servicio y se entrega un ticket personalizado."
                                    }
                                },
                                {
                                    "@type":"Question",
                                    "name":"¿El servicio de gas estacionario es seguro?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"Así es, debido a que al tener el tanque de forma fija en un sitio, se evita que éste sea sacudido o tenga movimientos bruscos que pongan en riesgo su integridad."
                                    }
                                },
                                {
                                    "@type":"Question",
                                    "name":"¿Qué hacer en caso de una fuga de gas?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"En caso de una fuga de gas, se debe cerrar inmediatamente la llave del tanque, ventilar el lugar, evitar contacto con corrientes eléctricas u otras fuentes de ignición, y una vez fuera del lugar donde ha ocurrido la fuga, llamar a los servicios de emergencia."
                                    }
                                },
                                {
                                    "@type":"Question",
                                    "name":"¿Cómo me puedo contactar para solicitar el servicio?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"Para solicitar el servicio de gas a domicilio, solamente es necesario llamar o enviar un mensaje de WhatsApp al número: (81) 8360 1100 o bien llenar el formulario que se encuentra al final de esta página. Uno de nuestros asesores le responderá para realizar la contratación."
                                    }
                                },
                                {
                                    "@type":"Question",
                                    "name":"¿Cómo puedo saber cuál es el tamaño adecuado del tanque de gas para mis necesidades?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"Lo más común para casa habitación son tanques de 120, 160 y 300 litros."
                                    }
                                },
                                {
                                    "@type":"Question",
                                    "name":"Cuando hago un pedido, ¿cuánto tiempo tomaría para que el servicio de gas estacionario sea entregado en mi hogar?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"El tiempo estimado es durante el transcurso del día y se programa con base en las rutas de las unidades."
                                    }
                                }
                            ]
                        }
                    `}
                </script>
            </Helmet>

            <Header />

            {/* SEC1 */}
            <div className="sec1 d-pad" id="estacionario">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center text-white">
                            <p className="mb-3 mt-3"> ¡Directo a tu hogar o negocio! </p>
                            <h1 className="fw-bold fs-50"> Servicio de gas estacionario </h1>
                            <p className="mt-4"> Realiza tu pedido de Gas LP </p>
                            <a href="#contacto" className="btn-cta mt-2 mb-4"> CONTÁCTANOS </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="mb-5 mt-5 ff-lato">
                                El servicio de gas estacionario consiste en Gas LP almacenado en su forma líquida, a altas <br />
                                presiones, en un tanque especial de metal. Para solicitar gas a domicilio, es necesario contar con <br />
                                uno de estos tanques, los cuales vienen en una amplia gama de tamaños.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC3 */}
            <div className="sec3 d-pad bg-grey" id="cilindros">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-5 fs-18">Conoce los beneficios que tiene el servicio gas estacionario para tu hogar o negocio</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon align-items-center">
                                <img src={icono1} className="w-25" />
                                <div class="fs-15 ms-4 ">
                                    <p class="fw-bold">Indicador de nivel</p>
                                    <p class="mb-0 me-3">
                                        Los tanques estacionarios cuentan con un indicador que permite conocer la cantidad de gas restante,
                                        lo que hace fácil saber cuándo es momento de pedir gas a domicilio.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono2} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Rendimiento</p>
                                    <p class="mb-0 me-3">
                                        Normalmente, los tanques de gas tienen una mayor capacidad que los cilindros,
                                        lo que se traduce en un mayor rendimiento.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono3} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Conveniencia</p>
                                    <p class="mb-0 me-3">
                                        El servicio de gas a domicilio evita que los usuarios se tengan que trasladar con sus cilindros
                                        hasta una estación de gas LP para realizar una recarga, por lo que se vuelve algo más práctico y cómodo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono4} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Ahorro de energía</p>
                                    <p class="mb-0 me-3">
                                        El gas estacionario ofrece un ahorro significativo de energía en comparación con otros combustibles convencionales.
                                        Gracias a su eficiencia y capacidad calorífica, es una excelente opción para negocios y hogares.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono5} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Impacto en el medio ambiente</p>
                                    <p class="mb-0 me-3">
                                        Usar gas estacionario tiene un impacto positivo en el medio ambiente, debido a que el gas LP emite menos contaminantes durante su
                                        combustión, por lo que resulta en una opción más ecológica.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono6} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Seguridad</p>
                                    <p class="mb-0 me-3">
                                        Estamos comprometidos con el cumplimiento de altos estándares y protocolos en el manejo del gas LP, con el fin de mantener
                                        un servicio de gas a domicilio completamente seguro.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative text-center mt-5">
                        <div className="btn-box bg-grey-01 position-absolute translate-middle start-50">
                            <a href="#form" className="btn-cta fw-bold">REALIZA TU PEDIDO</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC4*/}
            <div className="sec4 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <h2 className="fw-bold mb-4 ff-lato fs-18"> Datos sobre el servicio de gas estacionario </h2>
                            <p className="mb-5 text-center">
                                <li> Los medidores de los tanques de gas marcan el porcentaje de llenado, no la cantidad de litros que hay. </li>
                                <li> Por seguridad, los tanques de Gas LP no deben llenarse más del 90%. </li>
                                <li> Los tanques estacionarios tienen una vida útil de 10 años. </li>
                                <li> Es recomendable solicitar el servicio de gas a domicilio al momento de que el medidor del tanque de gas baje del 10%. </li>
                                <li> Para conocer la cantidad en litros que el medidor marca en porcentaje, éste debe multiplicarse por la capacidad total del tanque. </li>
                                <li> Conocer la capacidad total del tanque de gas que se posee es de gran importancia. </li>
                                <li> Antes de llenar el tanque, es importante identificar el porcentaje de carga inicial, así como el final después del llenado. </li>
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <img src={img1} className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC11 */}
            <div className="faqs">
                <div className="container">
                    <h3 className="fw-bold fs-25 mb-5 text-center">Preguntas frecuentes sobre nuestro servicio de gas a domicilio</h3>
                    <div class="accordion" id="accordionExample">
                        {/* Pregunta1 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ¿Qué es el servicio de gas estacionario?
                                </button>
                            </h3>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Es el servicio de <strong> gas a domicilio </strong> por medio del cual se suministra gas LP a un tanque estacionario en un hogar
                                    o negocio.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta2 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Cómo es el proceso para solicitar el servicio de gas estacionario?
                                </button>
                            </h3>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    El pedido puede realizarse por llamada, WhatsApp o app para programar el servicio. Las unidades acuden a los
                                    domicilios, son monitoreadas hasta surtir el servicio y se entrega un ticket personalizado.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta3 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿El servicio de gas estacionario es seguro?
                                </button>
                            </h3>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Así es, debido a que al tener el tanque de forma fija en un sitio, se evita que este sea sacudido o
                                    tenga movimientos bruscos que pongan en riesgo su integridad.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta4 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿Qué hacer en caso de una fuga de gas?
                                </button>
                            </h3>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    En caso de una fuga de gas, se debe cerrar inmediatamente la llave del tanque, ventilar el lugar, evitar contacto
                                    con corrientes eléctricas u otras fuentes de ignición, y una vez fuera del lugar donde ha ocurrido la fuga,
                                    llamar a los servicios de emergencia.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta5 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    ¿Cómo me puedo contactar para solicitar el servicio?
                                </button>
                            </h3>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Para solicitar el servicio de <strong> gas a domicilio </strong> , solamente es necesario llamar al número: (33) 3151 0381
                                    o bien llenar el formulario que se encuentra al final de esta página. <br />
                                    Uno de nuestros asesores le responderá para realizar la contratación.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta6 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    ¿Cómo puedo saber cuál es el tamaño adecuado del tanque de gas para mis necesidades?
                                </button>
                            </h3>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Lo más común para casa habitación son tanques de 120, 160 y 300 litros.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta7 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Cuando hago un pedido, ¿cuánto tiempo tomaría para que el servicio de gas estacionario sea entregado en mi hogar?
                                </button>
                            </h3>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    El tiempo estimado es durante el transcurso del día y se programa con base en las rutas de las unidades.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC12 */}
            <div className="sec12 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mb-5">Obtén un suministro <br /> constante de Gas LP para <br /> tu hogar o negocio.</h2>
                            <img src={footer} />
                        </div>
                        <div className="col-md-5">
                            <p className="mb-4">
                                ESCRÍBENOS, ESTAMOS PARA ATENDERTE
                            </p>
                            <div className="contacto" id="form">
                                <form onSubmit={onSubmitHandler}>
                                    <input type="text" name="nombre" placeholder="Nombre:" onChange={handleChange} value={lead.nombre || ""} required />
                                    <input type="email" name="email" placeholder="Email:" onChange={handleChange} value={lead.email || ""} required />
                                    <input type="text" name="servicio" placeholder="Servicio:" onChange={handleChange} value={lead.servicio || ""} required />
                                    <input type="tel" name="telefono" placeholder="Teléfono:" onChange={handleChange} value={lead.telefono || ""} required />
                                    <textarea name="mensaje" placeholder="Mensaje:" onChange={handleChange} value={lead.mensaje || ""} required />
                                    <button type="submit" className="btn-cta mt-3"> Enviar </button>
                                </form>
                            </div>
                        </div>
                        <div className="coml-md-1"></div>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default GasEstacionario;